<template>
<div id="Footer">    
  <CommonAlert2Func ref="commonAlert2Func" id="footerLoginAlert" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
<nav class="navbar  navbar-light bg-white fixed-bottom py-1" id="footerTeaching" :style="isTeaching? 'z-index: 1080; background-color:white;':'z-index: 1000; background-color:white;'"  data-container="body" customClass="container" data-color="primary" data-bs-placement="top"  :data-bs-content="teachingname">
    <div class="col-12 px-0">
    <div class="d-flex">
        <div class="col-3 text-center" @click.stop="changeRouter('Home')">
          <img :src="getHomeSrc().imgSrc" class="avatar avatar-nicole-sm2 rounded-0"> 
            <h6 :class="getHomeSrc().fontName">首頁</h6>
        </div>       
        <div class="col-3 text-center" @click.stop="changeRouter('DailyLearn')">
          <img :src="getDailySrc().imgSrc" class="avatar avatar-nicole-sm2 rounded-0"> 
            <h6 :class="getDailySrc().fontName">每日學習</h6>
        </div>        
        <div class="col-3 text-center" @click.stop="changeRouter('DeepLearn')">
          <img :src="getDepthSrc().imgSrc" class="avatar avatar-nicole-sm2 rounded-0"> 
            <h6 :class="getDepthSrc().fontName">深度學習</h6>
        </div>       
        <div class="col-3 text-center" @click.stop="changeRouter('Mine')">
          <img :src="getMineSrc().imgSrc" class="avatar avatar-nicole-sm2 rounded-0"> 
            <h6 :class="getMineSrc().fontName">我的</h6>
        </div>
    </div>
    </div>
</nav>
</div>
</template>

<script>
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'
export default {
  name: 'Footer',
  components: {
      CommonAlert2Func,
  },
  data () {
    return { 
      title: "",
      content: "",
      btnNameUp: "",
      btnNameDown: "",
      onFuncUp: Function,
      onFuncDown: Function,
      backUrl: "",
      isTeaching: false,
    }
  },
  props: {
    fname: String,
    teachingname: String
  },
  methods:{
    getRouter(){
      var query = "";
      if(this.$route.query && this.$route.query != undefined){
        query = Object.keys(this.$route.query)
        .map(key => {
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent(this.$route.query[key])
          )
        })
        .join('&');
      }
      if(query != ""){
        query = this.$route.name+"?"+query;
      }else{
        query = this.$route.name;
      }
      return query;
    },
    changeRouter(name){
        console.log(`isTeaching ==> ${this.isTeaching}`);
        if(name == this.$route.name || this.isTeaching) return;
        if (name == 'DailyLearn') {
          if (this.$GlobalData.uid == "" || this.$GlobalData.uid < 1) {
            this.backUrl = name;
            this.openLoginAlert(this.openLogin);
            return;
          }
        }
        this.$router.push(name);
    },
    changeToWeb(name){
      this.$JsBridge.callHandler('openWebView',name, this.changeToWebCallback);
    },
    changeToWebCallback(data){

    },
    openLoginAlert(funcUp){
        this.title = '完成註冊';
        this.content = '為你留下學習的紀錄';
        this.btnNameUp = '馬上註冊';
        this.btnNameDown = '繼續逛逛';
        this.onFuncUp = funcUp;          
        this.$refs.commonAlert2Func.onShow();
    },
    openLogin(){
      this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl: this.backUrl,skipBack:`Home`, type:""}), this.openLoginCallback);
    },
    openLoginCallback(){
      
    },
    getMineSrc(){
      var imgSrc = "assets/img/ic_tab_me_unselected.png";
      var fontName = "mb-0 text-muted small text-simbold";
      if(this.$route.name && this.$route.name != ""){
        switch(this.$route.name){
          case "MyCoursesCustom":
          case "MyCoursesDetail":
          case "UserTerms":
          case "PrivacyPolicy":
          case "QuestionRecordList":
          case "QuestionRecordDetail":
          case "Mine":
          case "OfflineListen":
          case "Collect":
          case "CollectLessonEssay":
          case "CollectLessonVideo":
          case "CollectLessonWord":
          case "CollectLessonSentence":
          case "CollectExamGroup":
          case "MessageList":
          case "MessageContent":
          case "OrderProduct":
          case "MemberSubscription":
          case "ContactService":
          case "Setting":
          case "ContactServiceList":
          case "ContactServiceDetail":
          case "Quiz":
          case "CommonQuestion":
          case "LearningRecord":
          case "MemberProfile":
          case "RecommendFriend":
          case "OrderRecord":
            imgSrc = 'assets/img/ic_tab_me.png';
            fontName = 'mb-0 small text-simbold text-success';
        }
      }
      return {imgSrc: imgSrc, fontName: fontName};
    },
    getDepthSrc(){
      var imgSrc = "assets/img/ic_tab_course_unselected.png";
      var fontName = "mb-0 text-muted small text-simbold";
      if(this.$route.name && this.$route.name != ""){
        switch(this.$route.name){
          case "DeepLearn":
          case "DeepLearnList":
          case "DeepLearnDetail":
            imgSrc = 'assets/img/ic_tab_course.png';
            fontName = 'mb-0 small text-simbold text-success';
        }
      }
      return {imgSrc: imgSrc, fontName: fontName};
    },
    getDailySrc(){
      var imgSrc = "assets/img/ic_tab_dailyessay_unselected.png";
      var fontName = "mb-0 text-muted small text-simbold";
      if(this.$route.name && this.$route.name != ""){
        switch(this.$route.name){
          case "CustomLevelSetting":
          case "CustomLevelSelect":
          case "CustomLevelDescription":
          case "LevelExamIntro":
          case "LevelExamIntroReciprocal":
          case "LevelExamStart":
          case "LevelExamComplete":
          case "LevelExamHistory":
          case "DailyLearn":
            imgSrc = 'assets/img/ic_tab_dailyessay.png';
            fontName = 'mb-0 small text-simbold text-success';
        }
      }
      return {imgSrc: imgSrc, fontName: fontName};
    },
    getHomeSrc(){
      var imgSrc = "assets/img/ic_tab_home_unselected.png";
      var fontName = "mb-0 text-muted small text-simbold";
      if(this.$route.name && this.$route.name != ""){
        switch(this.$route.name){
          case "Home":
          case "ListeningExam":
          case "ListeningExamStart":
          case "ListeningExamComplete":
          case "ListeningExamView":
          case "EverybodyLearn":
          case "TopicLearn":
          case "LevelLearn":
          case "VideoArea":
          case "UpgradeVip":
          case "SpecialUpgradeVip":
          case "ExtendList":
          case "ExtendDetail":
            imgSrc = 'assets/img/ic_tab_home.png';
            fontName = 'mb-0 small text-simbold text-success';
        }
      }
      return {imgSrc: imgSrc, fontName: fontName};
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
