<template>
<div id="Header">  

</div>
</template>

<script>
import { oltConsole , oAC } from "@/lib/oltModule/initClient";;
export default {
  name: 'Header', 
  props: {
    reloadfunc: Function,
    onorientationchangefunc: Function,
  },
  data() {
        return { 
          logoutCallback:Function,
          tempFunc: Function,
        }
  },
  methods:{
    onorientationchange(){
      if(this.onorientationchangefunc != null){
        this.onorientationchangefunc();
      }
      this.$nextTick(() => {
          initNav();
      });
    },
    reloadView(name){
      var index = name.indexOf("?");
      var query = {};
      if(index != -1){
        var searc = name.substring(index, name.length);  
        const urlSearchParams = new URLSearchParams(searc);   
        query = Object.fromEntries(urlSearchParams.entries()); 
        name = name.substring(0, index);
      }
      if(name == this.$route.name){
        if(this.reloadfunc != null && this.reloadfunc != undefined){
          this.reloadfunc(name, query);
        }
      }
    },
    signOut(){
      this.$GlobalData.uid = "";
      this.$GlobalData.user = {};
      this.$router.push({
        name: "Home",
      });
    },
    signVueOutCallback(){ 
      this.$GlobalData.uid = "";
      this.$GlobalData.user = {};
      if(this.logoutCallback != null && this.logoutCallback != undefined){
          this.logoutCallback(true);
          this.logoutCallback = null;
      }   
    },
    logCallback(data){},
    isLogOut(func){
      this.tempFunc = func;
      if (this.$JsBridge.isAndroid()) {
        this.$JsBridge.callHandler('checkNetwork', "", this.checkNetworkCallback);
      } else {
        if(this.$GlobalData.check_login_status == "Y"){
          oAC.getAllRecord(oAC.getApiParam({
            apiName: 'login_check'
          }), {uid:this.$GlobalData.uid}).then(
          res => 
              {
                if(res.user_device_token != null && res.user_device_token != undefined && res.user_device_token.length > 0){
                  var token = res.user_device_token[0].token;
                  if(token != "" && token != this.$GlobalData.token){
                    var dateTime = Date.now();
                    var timestamp = Math.floor(dateTime / 1000);
                    var modifyt = res.user_device_token[0].modifyt;
                    var modifytDateTime = new Date(Date.parse(modifyt.replace(/-/g, '/')));
                    var modifytTimestamp = Math.floor(modifytDateTime / 1000);
                    /*
                    console.log(`dateTime => ${dateTime}`);
                    this.$JsBridge.callHandler('log', `dateTime => ${dateTime}`, logCallback);
                    console.log(`timestamp => ${timestamp}`);
                    this.$JsBridge.callHandler('log', `timestamp => ${timestamp}`, logCallback);
                    console.log(`modifyt => ${modifyt}`);
                    this.$JsBridge.callHandler('log', `modifyt => ${modifyt}`, logCallback);
                    console.log(`modifytDateTime => ${modifytDateTime}`);
                    this.$JsBridge.callHandler('log', `modifytDateTime => ${modifytDateTime}`, logCallback);
                    console.log(`modifytTimestamp => ${modifytTimestamp}`);
                    this.$JsBridge.callHandler('log', `modifytTimestamp => ${modifytTimestamp}`, logCallback);
                    console.log(`timestamp - modifytTimestamp => ${timestamp - modifytTimestamp}`);
                    this.$JsBridge.callHandler('log', `timestamp - modifytTimestamp => ${timestamp - modifytTimestamp}`, logCallback);
                    this.$JsBridge.callHandler('log', `GlobalData.check_login_time ==> ${GlobalData.check_login_time}`, logCallback);
                    */
                    if((timestamp - modifytTimestamp) > this.$GlobalData.check_login_time){ 
                      this.logoutCallback = func;
                      this.$JsBridge.callHandler('signVueOut', "", this.signVueOutCallback);      
                    }else{
                      if(func != null && func != undefined){
                        func(false);
                      }
                    }
                  }else{
                    if(func != null && func != undefined){
                      func(false);
                    }
                  }
                }else{
                  if(func != null && func != undefined){
                    func(false);
                  }
                }
              }
          )
          .catch((err)=> {
              this.$JsBridge.callHandler('log', `ERROR : ${err}`, logCallback);
              if(func != null && func != undefined){
                func(false);
              }
              oltConsole.log(err);
              console.log("err: " + err);
          });
        }        
      }

    },
    checkNetworkCallback(res){
      if (res == "Y") {
        if(this.$GlobalData.check_login_status == "Y"){
          oAC.getAllRecord(oAC.getApiParam({
            apiName: 'login_check'
          }), {uid:this.$GlobalData.uid}).then(
          res => 
              {
                if(res.user_device_token != null && res.user_device_token != undefined && res.user_device_token.length > 0){
                  var token = res.user_device_token[0].token;
                  if(token != "" && token != this.$GlobalData.token){
                    var dateTime = Date.now();
                    var timestamp = Math.floor(dateTime / 1000);
                    var modifyt = res.user_device_token[0].modifyt;
                    var modifytDateTime = new Date(Date.parse(modifyt.replace(/-/g, '/')));
                    var modifytTimestamp = Math.floor(modifytDateTime / 1000);
                    /*
                    console.log(`dateTime => ${dateTime}`);
                    this.$JsBridge.callHandler('log', `dateTime => ${dateTime}`, logCallback);
                    console.log(`timestamp => ${timestamp}`);
                    this.$JsBridge.callHandler('log', `timestamp => ${timestamp}`, logCallback);
                    console.log(`modifyt => ${modifyt}`);
                    this.$JsBridge.callHandler('log', `modifyt => ${modifyt}`, logCallback);
                    console.log(`modifytDateTime => ${modifytDateTime}`);
                    this.$JsBridge.callHandler('log', `modifytDateTime => ${modifytDateTime}`, logCallback);
                    console.log(`modifytTimestamp => ${modifytTimestamp}`);
                    this.$JsBridge.callHandler('log', `modifytTimestamp => ${modifytTimestamp}`, logCallback);
                    console.log(`timestamp - modifytTimestamp => ${timestamp - modifytTimestamp}`);
                    this.$JsBridge.callHandler('log', `timestamp - modifytTimestamp => ${timestamp - modifytTimestamp}`, logCallback);
                    this.$JsBridge.callHandler('log', `GlobalData.check_login_time ==> ${GlobalData.check_login_time}`, logCallback);
                    */
                    if((timestamp - modifytTimestamp) > this.$GlobalData.check_login_time){ 
                      this.logoutCallback = this.tempFunc;
                      this.$JsBridge.callHandler('signVueOut', "", this.signVueOutCallback);      
                    }else{
                      if(this.tempFunc != null && this.tempFunc != undefined){
                        this.tempFunc(false);
                      }
                    }
                  }else{
                    if(this.tempFunc != null && this.tempFunc != undefined){
                      this.tempFunc(false);
                    }
                  }
                }else{
                  if(this.tempFunc != null && this.tempFunc != undefined){
                    this.tempFunc(false);
                  }
                }
              }
          )
          .catch((err)=> {
              this.$JsBridge.callHandler('log', `ERROR : ${err}`, logCallback);
              if(this.tempFunc != null && this.tempFunc != undefined){
                this.tempFunc(false);
              }
              oltConsole.log(err);
              console.log("err: " + err);
          });
        }  
      } else {

      }
    }
  },
  created(){  
  },
  mounted(){ 
    this.$JsBridge.registerMethod(this.reloadView); 
    this.$JsBridge.registerMethod(this.signOut);  
    this.$JsBridge.registerMethod(this.onorientationchange);  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
