<template>
<div id="CustomLevelDescription">  
  <Header/>
  <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>

  <body class=" bg-light">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          自訂等級
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>
    <div class="nicolespacetop"></div>
      <div class="container ">               
        <div class="position-relative" >
          <div class="card rounded-3 py-3 px-4 mt-3 mt-md-4 section-height-75" >
            <div class="d-flex align-content-center justify-content-between">
              <div class="text-20 text-dark text-bolder">{{name}}</div>
              <img src="assets/img/level4.png" class="avatar  ">
            </div>
            <h5 class="text-18 text-success text-bolder">{{englishName}}</h5>
            <h5 class="text-s text-muted lh-base my-3">{{description}}</h5>
          </div> 
          <div class=" position-absolute bottom-0 my-4 w-100 text-center ">
            <button type="button" class="btn btn-lg btn-outline-success rounded-pill w-75 text-s  nicole-noshadow mx-auto mb-2 mb-md-4 " @click="openLevelExam()">參加等級測驗</button>
            <button type="button" class="btn btn-lg  btn-success rounded-pill w-75  text-s mx-auto" @click="confirmLevel()">等級確認</button>
          </div> 
        </div>      
      </div>
      <div class="nicolespace"></div>  
      <!-- footer -->
      <div class="nicolespace"></div>
<!-- End footer -->
</body>
  <Footer/>
</div>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'


export default {
    name: 'CustomLevelDescription',
    components: {
        Header,
        Footer,
        CommonAlert2Func,

    },
    data () {
      return { 
        // api setting
        setDebugMode:false ,
        apiDebug: false,
        debugMode: false,
        apiName: "",
        form: [],
        fun: "",
        act: "",


        // Alert
        title: "",
        content: "",
        btnNameUp: "",
        btnNameDown: "",
        onFuncUp: Function,
        onFuncDown: Function,

        // data
        name: "",
        englishName: "",
        description: "",
        elid: "",

      }
    },
    methods: {
      setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
      onBack() {
        if (this.$route.query.back != undefined && this.$route.query.back != "" ) {
          this.$router.push(this.$route.query.back);
        } else {
          this.$router.push({
            name: "Home"
          });
        }
      },
      getAPI(){
        this.apiName = "config_get_english_level";
        oAC.getAllRecord(oAC.getApiParam(this), {elid:this.elid}).then(res => {
            if(res && res.english_level && res.english_level != ""){                        
                this.name = res.english_level[0].name;
                this.englishName = res.english_level[0].english_name;
                this.description = res.english_level[0].description;
            }
          }
        ).catch((err)=> {
            this.icon = "";
            oltConsole.log(err);
        });
        
      },
      openLevelExam(){
        this.$router.push({
          name: "LevelExamIntro",
          params:{
            back: "CustomLevelDescription"
          },
          query:{
            back: "CustomLevelDescription"
          }
        });

      },
      confirmLevel(){
        this.apiName="page_custom_level_description_confirm/"+this.$GlobalData.uid;
        this.form["elid"] = this.elid;
        oAC.putRecord(oAC.getApiParam(this), {}).then(res=> {
                this.openCustomLevelSetting();
        }).catch((err)=> {
          console.log(JSON.stringify(err));
          // oltConsole.log(err.response.status);
        });
      },
      openCustomLevelSetting(){
        this.$router.push({
          name: "CustomLevelSetting",
          query:{
            back:"CustomLevelSelect",
            elid: this.elid
          }
        });
      },
      getUserInfo(){
        this.apiName="page_member_profile";
        this.filterData['uid'] = this.$GlobalData.uid;
        oAC.getAllRecord(oAC.getApiParam(this), this.filterData).then(res => {


        }).catch((err)=> {
          oltConsole.log(err);
        });
      }
    },
    created(){  
      if (this.$route.params.elid != undefined) {
        this.elid = this.$route.params.elid;
      } else if (this.$route.query.elid != undefined) {
        this.elid = this.$route.query.elid;
      }
      if (this.$JsBridge.isAndroid) {
          this.setOnBack();
      }
      this.getAPI();
    }

};
</script>

