<template>
    <div id="CommonAlert2Func">
        <div class="modal fade " :id="modalId" tabindex="-1" role="dialog" aria-labelledby="notice1Label" aria-hidden="true">
            <div class="modal-sm modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body text-center" >
                        <div class="h3 mt-3 mb-4 fw-bolder">{{title}}</div>
                        <div class="h5 mt-2 ">{{content}}</div>
                        <div class="mt-4">
                            <button type="button" class="btn btn-success rounded-pill w-75" @click="onFuncUp">{{btnnameup}}</button>
                            <button type="button" class="btn btn-outline-success rounded-pill w-75 text-s mt-2 nicole-noshadow" @click="onFuncDown">{{btnnamedown}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CommonAlert2Func',    
    props: {
        onfuncup: Function,
        onfuncdown: Function,
        title: String,
        content: String,
        btnnameup: String,
        btnnamedown: String,
        id: String,
    }, 
    data () {
        return { 
        modalId: "",
        }
    },
    methods: {
        onShow(){
            $('#'+this.modalId).modal("show");
        },
        onHide(){
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#'+this.modalId).modal("hide");
        },
        onFuncUp(){
            if(this.onfuncup != null && this.onfuncup != undefined){
                $('#'+this.modalId).on('hidden.bs.modal', () => {
                    $('#'+this.modalId).off('hidden.bs.modal');
                    this.onfuncup();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#'+this.modalId).modal("hide");
        },
        onFuncDown(){
            if(this.onfuncdown != null && this.onfuncdown != undefined){
                $('#'+this.modalId).on('hidden.bs.modal', () => {
                    $('#'+this.modalId).off('hidden.bs.modal');
                    this.onfuncdown();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#'+this.modalId).modal("hide");
        },
    },
    created() {  
        if(this.id && this.id != "" && this.id != undefined){
            this.modalId = this.id;
        }else{
            this.modalId = "commonAlert2Func";
        }
    },
    mounted(){  
    }
};

</script>